.chakra-radio__control{
    background-color: rgb(197 199 212);
}

input{
    border: none;
}

input::placeholder{
    color: #6b6b6d;
}

input:focus{
    outline: none;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type=number]{
  -moz-appearance: textfield;
}