.parallax {
    transform: translateZ(0);
    backface-visibility: hidden;
    perspective: 1000px;
  }
  
  .parallax::before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transform: translateZ(-1px) scale(2);
    background-image: inherit;
    background-size: cover;
    background-position: center;
    z-index: -1;
  }