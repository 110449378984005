.breadcrumb-container {
    display: flex;
    flex-wrap: wrap; /* Allows items to wrap to the next line */
  }
  
  .breadcrumb-container .chakra-breadcrumb__list {
    display: flex;
    flex-wrap: wrap; /* Allows breadcrumb items to wrap to the next line */
  }
  
  @media (max-width: 768px) {
    .breadcrumb-container {
      justify-content: left; /* Center the breadcrumb on smaller screens */
    }
  }