.container {
    width: 100%;
    background-color: #fcfcfc;
    border-radius: 10px;
  }
  
  .responsive-table {
    width: 100%;
    border-collapse: collapse;
  }
  
  .responsive-table th,
  .responsive-table td {
    border: 1px solid #333;
    padding: 10px 5px 10px 5px;
    text-align: center;
  }
  
  .responsive-table th {
    background-color: #000814;
    color: white;
  }
  
  /* .responsive-table td:nth-child(odd) {
    background-color: #f5f5f5;
  } */
  
  /* .responsive-table td:nth-child(even) {
    background-color: #4caf50;
  } */
  
  /* @media screen and (max-width: 768px) {
    .responsive-table thead {
      display: none;
    }
    
    .responsive-table, 
    .responsive-table tbody, 
    .responsive-table tr, 
    .responsive-table td {
      display: block;
      width: 100%;
    }
    
    .responsive-table tr {
      margin-bottom: 15px;
    }
    
    .responsive-table td {
      text-align: right;
      padding-left: 50%;
      position: relative;
    }
    
    .responsive-table td::before {
      content: attr(data-label);
      position: absolute;
      left: 0;
      width: 50%;
      padding-left: 15px;
      font-weight: bold;
      text-align: left;
    }
  } */