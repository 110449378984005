.container {
    width: 100%;
    background-color: #f8f8f8;
    border-radius: 10px;
  }
  
  h2 {
    text-align: center;
    margin-bottom: 20px;
  }
  
  .table {
    display: grid;
    grid-template-columns: 1fr 2fr;
    gap: 10px;
  }
  
  .row {
    display: contents;
  }
  
  .cell {
    padding: 10px;
    border: 1px solid #333;
  }
  
  .header {
    font-weight: bold;
    background-color: #eeecec;
  }